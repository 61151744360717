/* global $ */
/* eslint no-bitwise: 0 */

const generateUniqueId = () =>
  [...Array(10)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

const uniqueId = generateUniqueId();

$.$body.find('.image-slider').slick({
  // https://github.com/kenwheeler/slick/#settings
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  fade: true,
  // adaptiveHeight: true,
  accessibility: true,
  // focusOnChange: true,
  // ^ prefer using a custom afterChange callback to focus that takes sticky header into account
  prevArrow: `<div class="slick-prev"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7.6 13" style="enable-background:new 0 0 7.6 13;" xml:space="preserve" aria-labelledby="left-chevron-small-title-${uniqueId} left-chevron-small-desc-${uniqueId}"><title id="left-chevron-small-title-${uniqueId}">Left Arrow</title><desc id="left-chevron-small-desc-${uniqueId}">An arrow pointing left</desc><path d="M0.2,7.1l5.6,5.5C6.1,12.8,6.3,13,6.6,13c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L2.4,6.5  l4.9-4.8c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C7.1,0.1,6.8,0,6.6,0C6.4,0,6.1,0.1,5.9,0.3L0.3,5.8C0.1,6,0,6.2,0,6.5C0,6.7,0.1,7,0.2,7.1z"/></svg></div>`,
  nextArrow: `<div class="slick-next"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7.6 13" style="enable-background:new 0 0 7.6 13;" xml:space="preserve" aria-labelledby="right-chevron-small-title-${uniqueId} right-chevron-small-desc-${uniqueId}"><title id="right-chevron-small-title-${uniqueId}">Right Arrow</title><desc  id="right-chevron-small-desc-${uniqueId}">An arrow pointing right</desc><path d="M7.3,5.8L1.7,0.3C1.5,0.1,1.2,0,1,0C0.8,0,0.5,0.1,0.3,0.3C0.1,0.5,0,0.7,0,1c0,0.3,0.1,0.5,0.3,0.7l4.9,4.8l-4.9,4.8C0.1,11.5,0,11.7,0,12c0,0.3,0.1,0.5,0.3,0.7C0.5,12.9,0.7,13,1,13c0.2,0,0.5-0.1,0.7-0.3l5.6-5.5C7.5,7,7.5,6.7,7.5,6.5C7.6,6.2,7.5,6,7.3,5.8z"/></svg></div>`,
});

$.$body.find('.testimonial-slider').slick({
  // https://github.com/kenwheeler/slick/#settings
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  fade: true,
  dots: true,
  // adaptiveHeight: true,
  accessibility: true,
  // focusOnChange: true,
  // ^ prefer using a custom afterChange callback to focus that takes sticky header into account
  prevArrow: `<div class="slick-prev"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7.6 13" style="enable-background:new 0 0 7.6 13;" xml:space="preserve" aria-labelledby="left-chevron-small-title-${uniqueId} left-chevron-small-desc-${uniqueId}"><title id="left-chevron-small-title-${uniqueId}">Left Arrow</title><desc id="left-chevron-small-desc-${uniqueId}">An arrow pointing left</desc><path d="M0.2,7.1l5.6,5.5C6.1,12.8,6.3,13,6.6,13c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L2.4,6.5  l4.9-4.8c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C7.1,0.1,6.8,0,6.6,0C6.4,0,6.1,0.1,5.9,0.3L0.3,5.8C0.1,6,0,6.2,0,6.5C0,6.7,0.1,7,0.2,7.1z"/></svg></div>`,
  nextArrow: `<div class="slick-next"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 7.6 13" style="enable-background:new 0 0 7.6 13;" xml:space="preserve" aria-labelledby="right-chevron-small-title-${uniqueId} right-chevron-small-desc-${uniqueId}"><title id="right-chevron-small-title-${uniqueId}">Right Arrow</title><desc  id="right-chevron-small-desc-${uniqueId}">An arrow pointing right</desc><path d="M7.3,5.8L1.7,0.3C1.5,0.1,1.2,0,1,0C0.8,0,0.5,0.1,0.3,0.3C0.1,0.5,0,0.7,0,1c0,0.3,0.1,0.5,0.3,0.7l4.9,4.8l-4.9,4.8C0.1,11.5,0,11.7,0,12c0,0.3,0.1,0.5,0.3,0.7C0.5,12.9,0.7,13,1,13c0.2,0,0.5-0.1,0.7-0.3l5.6-5.5C7.5,7,7.5,6.7,7.5,6.5C7.6,6.2,7.5,6,7.3,5.8z"/></svg></div>`,
});
