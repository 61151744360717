/* global $, Cookies */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 10; // delay any scroll events by this many MS
const $topNav = $('.main-header');
const $html = $('html');
let topNavHeight = $topNav.innerHeight(); // eslint-disable-line no-unused-vars

// eslint-disable-next-line no-unused-vars
const positionStickySupport = (function() {
  const el = document.createElement('a');
  const mStyle = el.style;
  mStyle.cssText =
    'position:sticky;position:-webkit-sticky;position:-ms-sticky;';
  return mStyle.position.indexOf('sticky') !== -1;
})();

$html.toggleClass('nopositionsticky', !positionStickySupport);

// alert bar
const $alertBar = $.$body.find('.alert-bar');
let alertBarHeight = $alertBar.height() || 0;

// handle cases where the alert bar is taller than the viewport: falls
// back to position: relative
function stickyAlertCheck() {
  alertBarHeight = $alertBar.height() || 0;
  const isSticky = $alertBar.css('position') === 'sticky';
  if (alertBarHeight > $(window).height() && isSticky) {
    $alertBar.css({ position: 'relative' });
  } else if (alertBarHeight <= $(window).height() && !isSticky) {
    $alertBar.css({ position: 'sticky' });
  }
}

// this check relies on the alert.js code being in the site <head> tag,
// or at least running before these main scripts
if ($html.hasClass('ok-to-alert') || $html.hasClass('ok-to-alert__footer')) {
  $.$body.on('click', '.alert-bar__close', (e) => {
    e.preventDefault();
    const $alert = $(e.target).closest('.alert-bar');
    const { isCookied, modifier = '', cookievalue, expires } = $alert.data();
    if (!modifier) {
      alertBarHeight = 0;
      $topNav.removeClass('is-sticky'); // remove is-sticky on close
    }
    $alert.slideToggle('fast', () => {
      $alert.prop('hidden', true);
      topNavHeight = $topNav.innerHeight();
      $html.removeClass(`ok-to-alert${modifier}`); // remove ok-to-alert on close
    });

    if (isCookied) {
      Cookies.set(`alertbar${modifier}_clicked`, cookievalue || 'value', {
        expires: expires || 7,
      });
    }
  });

  // make sure the paralax gets switched off if the alert bar is taller
  // than the screen height

  if (positionStickySupport) {
    $(window).resize(stickyAlertCheck);
    stickyAlertCheck();
  }
}

// addSticky :: Int -> () -> IO (side-effect, adds or removes class)
// eslint-disable-next-line no-unused-vars
let canSticky = true;
let timeoutId = 0;
const checkScroll = (e = { nonEvent: true }) => {
  const { target = {}, nonEvent = false } = e;
  let { scrollingElement: { scrollTop } = {} } = target;

  if (!scrollTop) {
    scrollTop = $(window).scrollTop();
  }
  alertBarHeight = $alertBar.height() || 0;
  const alertPusNavSticky = topNavHeight + alertBarHeight;

  $html.toggleClass('scrolled-past-nav', scrollTop >= alertPusNavSticky);

  if (canSticky) {
    // if alert is active (ok-to-alert), we always want the header to be sticky
    if ($html.hasClass('ok-to-alert')) {
      $topNav.addClass('is-sticky');
      canSticky = true;
    } else {
      $topNav.toggleClass('is-sticky', scrollTop >= alertPusNavSticky);
      canSticky = false;
    }

    // $topNav.toggleClass('is-sticky', scrollTop >= alertPusNavSticky);
    // canSticky = false;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      canSticky = true;
      if (!nonEvent) {
        checkScroll();
      }
    }, 303);
  } else {
    topNavHeight = $topNav.innerHeight();
    // canSticky = true;
  }

  if (positionStickySupport) {
    $alertBar.toggleClass('social-hide', scrollTop < alertPusNavSticky);
  }
};
const addSticky = throttleDomAfterAsync(checkScroll, scrollEffectDelay);

$(window).on('scroll', addSticky);
addSticky();
