/* global $ */

// const scrollOffset = 72;
const scrollTime = 400;

const $top = $.$body.find('.top');

$.$body
  .on('click', 'a.scroll-read-more', function(e) {
    e.preventDefault();
    const navHeight = $top.innerHeight();
    // console.log({ navHeight });
    const scrollTop = $($(this).attr('href')).offset().top - navHeight;

    $('html, body').animate({ scrollTop }, scrollTime);
  })
  // landing page
  .on('click', '.smooth_scroll', function(e) {
    e.preventDefault();
    const $target = $($(this).attr('href')); // usually a #hash selector...

    if ($target.length) {
      const scrollTop = $target.offset().top - 40;

      // Animate
      $('html, body')
        .stop()
        .animate({ scrollTop }, scrollTime);
    }
  });

// used if we need more control over the hashlinks to account for the nav
// store the hash (DON'T put this code inside the $() function, it has to be executed
// right away before the browser can start scrolling!
// const target = window.location.hash.replace('#', '');
// const navHeight = $top.innerHeight();
// // delete hash so the page won't scroll to it
// window.location.hash = '';

// // now whenever you are ready do whatever you want
// // (in this case I use jQuery to scroll to the tag after the page has loaded)
// $(window).on('load', () => {
//   if (target) {
//     $('html, body').animate(
//       {
//         scrollTop: $(`${target}`).offset().top + navHeight,
//       },
//       700,
//       'swing',
//       () => {},
//     );
//   }
// });
