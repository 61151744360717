/* global $ */

import { gup } from '../helpers/params';

// BSD sourcing
const source = gup('utm_source') || gup('source');

$(document).ready(() => {
  if (source) {
    $.$body.find('.bsd-form').each(function() {
      const $form = $(this);
      if ($form.find('[name="source"]').length) {
        $form.find('[name="source"]').val(source);
      } else {
        $('<input/>', {
          type: 'hidden',
          name: 'source',
          value: source,
        }).appendTo($form);
      }
    });
  }
});
