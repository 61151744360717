// Utility functions for setting URL parameters.
/* eslint prefer-arrow-callback: 0  */
/* eslint no-nested-ternary: 0  */
/**
 * @param {String} searchString
 * @returns {String}
 */
export const extractParameter = (searchString) => (name) => {
  name = String(name).replace(/(\[|\])/g, '\\$1');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(searchString);
  return results === null ? '' : results[1];
};

/**
 * @param {String} parameter name
 * @returns {String} parameter value
 *
 * gup :: String -> String
 */

export const gup = extractParameter(window.location.href);

/**
 * @param {Object}
 * @returns {String}
 */
export const encodeParameterString = (obj = {}) =>
  Object.keys(obj).reduce((string, param) => {
    if (string !== '') {
      string += '&';
    }
    return string.concat(
      encodeURIComponent(param) + '=' + encodeURIComponent(obj[param]),
    );
  }, '');

// fixes a few doubly encoded cases
// sanitizeParams :: String -> String
export function sanitizeParams(string) {
  return String(string || '').replace(/[&"'<>)(]/g, function(c) {
    return c === '&'
      ? '&amp;'
      : c === '"'
        ? '&quot;'
        : c === "'"
          ? '&#039;'
          : c === '<'
            ? '&lt;'
            : '&gt;';
  });
}
export const toBooleanStrict = (str) => {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return str;
  }
};

export const processParams = (x) =>
  toBooleanStrict(sanitizeParams(window.decodeURIComponent(x)));

// decodeParameterString :: String -> Object
export function decodeParameterString(str) {
  const obj = {};
  String(str)
    .replace('?', '')
    .replace(/&amp;/gi, '&')
    .replace(/([^=&]+)=([^&]*)/g, function(m, key, value) {
      obj[decodeURIComponent(key)] = processParams(value);
    });
  return obj;
}
