// polyfills for native async delays
const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  (function() {
    let clock = Date.now();

    return function(callback) {
      const currentTime = +new Date();

      if (currentTime - clock > 16) {
        clock = currentTime;
        callback(currentTime);
      } else {
        // recursion wrapped in a setTimeout here
        // to unblock the browser in between loops.
        setTimeout(() => {
          // eslint-disable-next-line
          polyfill(callback);
        }, 0);
      }
    };
  })();

const requestIdleCallback =
  window.requestIdleCallback ||
  function(cb) {
    const start = Date.now();
    return setTimeout(() => {
      cb({
        didTimeout: false,
        timeRemaining: function() {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

// throttle, but only fires on trailing edge, after a delay of no similar events
// throttleDomAfterAsync :: (Function, Integer, Context) -> Function
export const throttleDomAfterAsync = (fn, ms = 0, scope) => {
  let timer = 0;
  return function(...args) {
    const context = scope || this;
    clearTimeout(timer);
    timer = setTimeout(
      () =>
        requestIdleCallback(
          () => requestAnimationFrame(() => fn.apply(context, args)),
          {
            timeout: ms,
          },
        ),
      ms,
    );
  };
};
