/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 20; // delay any scroll events by this many MS
const $dottedNav = $.$body.find('.dotted-nav');

const setActiveDot = () => {
  const { scrollTop } = document.documentElement;
  const _s = $.$body
    .find('.module.dotSection')
    .first()
    .attr('id');
  $dottedNav.find('a.active').removeClass('active');
  if (scrollTop > 100) {
    $dottedNav.find(`#anchor-${_s}`).addClass('active');
  }
};

if ($dottedNav.length) {
  // this seems like a lot of work to change one thing white on scroll
  const checkWhiteClass = throttleDomAfterAsync(() => {
    const windowSize = $(window).height() / 2;
    const scrollPosition = $(window).scrollTop();
    if (scrollPosition >= windowSize) {
      $dottedNav.removeClass('white');
    } else {
      $dottedNav.addClass('white');
    }
  }, scrollEffectDelay);

  $(window).on('scroll', checkWhiteClass);

  // activate dotted nav on load
  $(document).ready(() => setTimeout(setActiveDot, 100));

  // event to check which section is active
  const toggleNavColorScroll = throttleDomAfterAsync(() => {
    setTimeout(setActiveDot, 100);
    setTimeout(setActiveDot, 200);
  }, scrollEffectDelay);

  $(window).on('scroll', toggleNavColorScroll);
}
